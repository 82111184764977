<template>
  <div class="about">
    <h1>13 Days 13 Shorts</h1>
    <div class="about-dezzles">
      13 Days is a cool project ran by Omar Najam to help inspire people to create things that are all Halloweeny, and where Dezzles comes and does some ridiculous projects. There's usually some manner of bizarre
      text generators because Dezzles made one like 8 years ago and refuses to do anything BUT use the one generator for practically any idea he comes up with. One day he'll probably
      try something different, but I honestly don't expect him to follow through. He's even put together websites for previous years of 13 Days and done NOTHING, you can find
      the sites still if you know what you're looking for. He left me some notes about what to include in the blurb, something about ... Ugh of course, more random generators...
      Oh hey, he reckons this year he's gonna try and write a song or two, or (lol) a monologue... This should be a solid laugh... And not funny haha laugh...
    </div>
    <div class="sign-off">
      - Swamp Emu ❤️
    </div>
  </div>
</template>

<style scoped>
.sign-off {
  text-align: right;
}
.about {
  max-width: 800px;
  margin: auto;
  font-size: 1.5em;
}

.about-dezzles {
  text-align: justify;

}
</style>
